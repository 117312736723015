export class IntranetMovieSchedule {
	scheduleDays: Element[];

	constructor() {
		this.scheduleDays = Array.from(document.querySelectorAll(".schedule-day"));

		document.addEventListener("click", e => {
			let targetElement = e.target as HTMLElement;
			if (targetElement?.classList.contains("schedule-navbtn-prev")) {
				this.navigate(function (idx) { return idx - 1 });
			} else if (targetElement?.classList.contains("schedule-navbtn-next")) {
				this.navigate(function (idx) { return idx + 1 });
			}
		})

		if (document.querySelector(".schedule-day.is-active") == null) {
			document.querySelector(".schedule-day")?.classList.add("is-active");
		}
	}

	navigate(nextScheduleDayIndexAccessor: (index: number) => number) {
		var currentScheduleDay = document.querySelector(".schedule-day.is-active")!;
		var currentIndex = this.scheduleDays.indexOf(currentScheduleDay);

		var nextIndex = nextScheduleDayIndexAccessor(currentIndex);
		var nextScheduleDay = this.scheduleDays[nextIndex];

		currentScheduleDay.classList.remove("is-active");
		nextScheduleDay.classList.add("is-active");

		for (let button of document.querySelectorAll<HTMLButtonElement>(".schedule-navbtn-prev")) {
			button.disabled = nextIndex === 0;
		}
		for (let button of document.querySelectorAll<HTMLButtonElement>(".schedule-navbtn-next")) {
			button.disabled = nextIndex === this.scheduleDays.length - 1;
		}
	}
}
