import { Vue, Component, Prop } from "@wagich/vue-facing-decorator-metadata";
import VueMultiselect from "vue-multiselect";

import { jsonHeaders } from "../utils";
import { Interest, NewsletterSubscription, Interests, type InterestListItem, type SubscriptionResponse, SubscriptionAction } from "./models";
import { render } from "./newsletter-signup.html";
import { trackEvent } from "../analytics";

enum State {
	Normal = "normal",
	Loading = "loading",
	Subscribed = "subscribed",
	Updated = "updated",
	Error = "error"
}

@Component({
	render,
	components: {
		VueMultiselect,
	}
})
export default class NewsletterSignup extends Vue {
	@Prop({ default: false }) isInverted: boolean;
	@Prop() interests: string;

	state: State = State.Normal;

	selectedInterests: InterestListItem[] = Interests;
	firstname: string = "";
	lastname: string = "";
	email: string = "";
	emailError: boolean = false;

	interestDefinitions = Interests;

	created() {
		if (this.interests?.length > 0) {
			const interest = Interests.find(i => i.displayName === this.interests);
			if (interest != null) {
				this.selectedInterests = [interest];
			}
		}
	}

	async submit() {
		this.emailError = this.email === "";
		if (this.emailError) {
			return;
		}

		this.state = State.Loading;

		let subscription: NewsletterSubscription = {
			firstname: this.firstname,
			lastname: this.lastname,
			email: this.email,
			interests: this.selectedInterests.map(i => i.value)
		}

		let response = await fetch("/api/newsletter", {
			method: "POST",
			body: JSON.stringify(subscription),
			headers: jsonHeaders
		});

		if (response.status === 200) {
			let subscriptionResponse: SubscriptionResponse = await response.json();
			switch (subscriptionResponse.subscriptionAction) {
				case SubscriptionAction.Subscribed:
					this.state = State.Subscribed;
					trackEvent("Newsletter angemeldet");
					break;
				case SubscriptionAction.Updated:
					this.state = State.Updated;
					break;
			}

		} else {
			this.state = State.Error;
		}
	}
}
