import Masonry from "masonry-layout";
import ReadSmore from "read-smore";

import { HeaderAnimation } from "./header-animation";
import { eventBus } from "./event-bus";
import { ScrollIntoViewEvent } from "./tickets/events";
import { Fancybox } from "@fancyapps/ui";

export class Page {
	constructor() {
		new HeaderAnimation();

		document.querySelector("#navigationToggle")?.addEventListener("click", () => {
			document.body.classList.toggle("is-navigation-open");
		});

		let backButton = document.querySelector(".page-back-button");
		if (history.length === 1) {
			backButton?.remove();
		} else {
			backButton?.addEventListener("click", () => {
				history.back();
			});
		}

		for (let element of document.querySelectorAll(".dropdown")) {
			element.addEventListener("click", e => {
				e.stopPropagation();
			});
			element.querySelector(".dropdown-trigger")?.addEventListener("click", e => {
				let currentTarget = e.currentTarget as HTMLElement;
				currentTarget.parentElement?.classList.toggle("is-active");
			});
		}
		document.body.addEventListener("click", e => {
			for (let element of document.querySelectorAll(".dropdown")) {
				element.classList.remove("is-active");
			}
		});

		for (let element of document.querySelectorAll(".common-navigation__toggle")) {
			element.addEventListener("click", e => {
				let navigation = document.querySelector<HTMLElement>(".common-navigation");
				navigation?.classList.remove("has-focused-item");
				document.querySelector(".columns--navigation-level-1 .is-focused")?.classList.remove("is-focused");

				navigation?.classList.toggle("is-collapsed");
				navigation?.classList.toggle("is-expanded");
			});
		}

		let overviewTiles = document.querySelector(".overview-tiles");
		if (overviewTiles != null) {
			new Masonry(overviewTiles, {
				itemSelector: ".overview-tile"
			});
		}

		document.querySelector("[data-action='scrollToTickets']")?.addEventListener("click", () => eventBus.emit(ScrollIntoViewEvent));

		if (location.hash.length > 0) {
			let view = location.hash.substring(1);
			document.querySelector(`.common-navigation a[href$='/filme/${view}']`)?.parentElement?.classList.add("is-active");
		}

		ReadSmore(document.querySelectorAll(".js-read-smore"), {
			lessText: "‹ weniger",
			moreText: "weiterlesen ›",
			charsCount: 120,
			isInline: true,
		}).init();

		Fancybox.bind(".asset.is-zoomable", {
			groupAttr: "data-gallery",
		});
	}
}
