<div>
	<transition name="fade" mode="out-in">
		<div class="newsletter-signup" :class="{ 'newsletter-signup--inverted': isInverted }" v-if="state === 'normal' || state === 'loading'">
			<div class="field">
				<div class="control">
					<vue-multiselect :options="interestDefinitions" :searchable="false" :close-on-select="false" :multiple="true" track-by="value" :hide-selected="true" label="displayName" placeholder="" select-label="" v-model="selectedInterests">
						<template #selection="{ values, remove }">
							<span class="tags">
								<span class="tag is-primary" v-for="value of values">
									{{ value.displayName }}
									<button class="delete is-small" @click="remove(value)"></button>
								</span>
							</span>
						</template>
					</vue-multiselect>
				</div>
			</div>

			<b-field :type="emailError ? 'is-danger' : undefined" :message="emailError ? 'Bitte geben Sie Ihre E-Mail-Adresse an.' : undefined">
				<b-input placeholder="E-Mail (erforderlich)" type="email" autocomplete="email" v-model="email"/>
			</b-field>

			<div class="field is-horizontal">
				<div class="field-body">
					<div class="field">
						<div class="control">
							<input type="text" class="input" placeholder="Vorname" autocomplete="given-name" v-model="firstname" />
						</div>
					</div>
					<div class="field">
						<div class="control">
							<input type="text" class="input" placeholder="Nachname" autocomplete="family-name" v-model="lastname" />
						</div>
					</div>
				</div>
			</div>

			<div class="field">
				<div class="control">
					<b-button :type="isInverted ? 'is-dark' : 'is-primary'" @click="submit" :loading="state === 'loading'">Newsletter abonnieren</b-button>
				</div>
			</div>
		</div>

		<div class="newsletter-form-success" v-if="state === 'subscribed'">
			<p>Um die Anmeldung abzuschliessen, klicken Sie bitte auf den Link in der E-Mail, die wir soeben an Sie geschickt haben.</p>
		</div>

		<div class="newsletter-form-success" v-if="state === 'updated'">
			<p>Wir haben Ihre Daten aktualisiert.</p>
		</div>

		<div class="newsletter-form-error" v-if="state === 'error'">
			<p>Oops, bei der Anmeldung ist ein Fehler aufgetreten. Bitte schicken Sie uns zur Anmeldung ein Mail an <a href="mailto:info@kino-thun.ch?subject=Newsletter-Anmeldung">info@kino-thun.ch</a></p>
		</div>
	</transition>
</div>
