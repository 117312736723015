import { Component, Vue } from "@wagich/vue-facing-decorator-metadata";

import NewsletterModal from "./newsletter-modal";

import { render } from "./newsletter-button.html";

@Component({
	render,
	components: {
		NewsletterModal
	}
})
export default class NewsletterButton extends Vue {
	isOpen: boolean = false;
}
