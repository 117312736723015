import { format as datefns_format, differenceInCalendarDays as datefns_dayDiff, parseISO } from "date-fns";
import { de as datefns_de } from "date-fns/locale";
import { Fancybox } from "@fancyapps/ui";
import currency from "currency.js";

export const swissCurrency = { symbol: "CHF ", separator: " " };

export function formatDate(date: string | Date, format: string) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	return datefns_format(date, format, { locale: datefns_de });
}

export function humanizeDate(date: string | Date) {
	if (typeof date === "string") {
		date = parseISO(date);
	}
	switch (datefns_dayDiff(new Date(), date)) {
		case 0:
			return "Heute";
		case 1:
			return "Morgen";
		default:
			return datefns_format(date, "d. MMMM", { locale: datefns_de });
	}
}

export const jsonHeaders = new Headers({
	"Content-Type": "application/json",
	"Accept": "application/json"
});

export const languages: { [lang: string]: string } = {
	"de": "Deutsch",
	"fr": "Französisch",
	"it": "Italienisch",
	"en": "Englisch",
	"de-CH": "Dialekt"
};

export enum LoadingState {
	Initial = "initial",
	Loading = "loading",
	Success = "success",
	Error = "error"
}

export function expandLanguages(showLanguages: string[], lowercase: boolean = false) {
	return showLanguages.map(lang => {
		let languageName = languages[lang];
		if (lowercase) {
			languageName = languageName.toLocaleLowerCase();
		}
		return languageName;
	}).join(", ");
}

const parser = new DOMParser();
export function playTrailer(html: string | null) {
	if (html == null) {
		return;
	}

	let doc = parser.parseFromString(html, "text/html");
	new Fancybox([
		{
			src: `<div style="position: relative; width: 80vw; padding-bottom: 45.25%; background: black;"><div style="position: absolute; width: 100%; height: 100%;">${html}</div>`,
			type: "html",
		}
	]);
}

export function parseDateWithoutTimezoneConversion(dateString: string) {
	let date = new Date(dateString);
	let timezoneOffset = getTimezone(dateString) * -1;
	return new Date(
		date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
		date.getUTCHours(), date.getUTCMinutes() + timezoneOffset, date.getUTCSeconds(), date.getUTCMilliseconds()
	);
}

function getTimezone(date: string) {
	let dateParts = splitDateString(date);
	if (dateParts.timezone) {
		return parseTimezone(dateParts.timezone);
	}
	return 0;
}

/*
 * Following code is from https://github.com/date-fns/date-fns/blob/v1.29.0/src/parse/index.js
 * 
 * Copyright © 2018 Sasha Koss
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the “Software”), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 * THE SOFTWARE IS PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
const parseTokenDateTimeDelimeter = /[T ]/;
const parseTokenPlainTime = /:/;
function splitDateString(dateString: string) {
	var dateStrings: { date?: string | null, time?: string, timezone?: string } = {};
	var array = dateString.split(parseTokenDateTimeDelimeter);
	var timeString;

	if (parseTokenPlainTime.test(array[0])) {
		dateStrings.date = null;
		timeString = array[0];
	} else {
		dateStrings.date = array[0];
		timeString = array[1];
	}

	if (timeString) {
		var token = parseTokenTimezone.exec(timeString);
		if (token) {
			dateStrings.time = timeString.replace(token[1], '');
			dateStrings.timezone = token[1];
		} else {
			dateStrings.time = timeString;
		}
	}

	return dateStrings
}


// timezone tokens
const parseTokenTimezone = /([Z+-].*)$/
const parseTokenTimezoneZ = /^(Z)$/
const parseTokenTimezoneHH = /^([+-])(\d{2})$/
const parseTokenTimezoneHHMM = /^([+-])(\d{2}):?(\d{2})$/

function parseTimezone(timezone: string): number {
	var token;
	var absoluteOffset;

	// Z
	token = parseTokenTimezoneZ.exec(timezone);
	if (token) {
		return 0;
	}

	// ±hh
	token = parseTokenTimezoneHH.exec(timezone);
	if (token) {
		absoluteOffset = parseInt(token[2], 10) * 60;
		return (token[1] === '+') ? -absoluteOffset : absoluteOffset;
	}

	// ±hh:mm or ±hhmm
	token = parseTokenTimezoneHHMM.exec(timezone);
	if (token) {
		absoluteOffset = parseInt(token[2], 10) * 60 + parseInt(token[3], 10);
		return (token[1] === '+') ? -absoluteOffset : absoluteOffset;
	}

	return 0
}
export function formatCurrency(value: currency | string) {
	if (value instanceof currency) {
		return value.format();
	} else {
		return currency(value, swissCurrency).format();
	}
}
