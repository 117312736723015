export class NewsletterSubscription {
	firstname: string;
	lastname: string;
	email: string;
	interests: Interest[];
}

export interface SubscriptionResponse {
	subscriptionAction: SubscriptionAction;
}

export enum SubscriptionAction {
	Subscribed = "subscribed",
	Updated = "updated"
}

export enum Interest {
	PremieresPreviewsEvents = "aff6716aea",
	CineDiner = "08f7ad0731",
	LadiesNight = "a4c441a49a",
	ClubCinema = "6a307f6f3b",
	Cinedolcevita = "4a4b8091cf"
}

export interface InterestListItem {
	value: Interest;
	displayName: string;
}

export const Interests: InterestListItem[] = [
	{
		value: Interest.PremieresPreviewsEvents,
		displayName: "Premieren, Vorpremieren & Events"
	},
	{
		value: Interest.CineDiner,
		displayName: "Ciné&Dîner"
	},
	{
		value: Interest.LadiesNight,
		displayName: "Ladies Night"
	},
	{
		value: Interest.ClubCinema,
		displayName: "Club Cinema"
	},
	{
		value: Interest.Cinedolcevita,
		displayName: "Cinedolcevita"
	},
]
