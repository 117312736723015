import { Vue, Component, Model } from "@wagich/vue-facing-decorator-metadata";

import { render } from "./newsletter-modal.html";
import NewsletterSignup from "./newsletter-signup";

@Component({
	render,
	components: {
		NewsletterSignup,
	}
})
export default class NewsletterModal extends Vue {
	@Model() isOpen: boolean;
}
