export class HeaderAnimation {
	private _contentTitle: HTMLElement;
	private _lastScrollY = 0;
	private _ticking = false;

	onScroll() {
		this._lastScrollY = window.scrollY;
		this.requestTick();
	}
	requestTick() {
		if (!this._ticking) {
			requestAnimationFrame(() => this.update());
			this._ticking = true;
		}
	}

	update() {
		this._contentTitle.style.paddingTop = `${this._lastScrollY / 1.75}px`;
		this._ticking = false;
	}

	constructor() {
		var contentTitle = document.querySelector<HTMLElement>(".keyvisual-container h1");
		if (contentTitle != null) {
			this._contentTitle = contentTitle;
			window.addEventListener("scroll", () => this.onScroll());
		}
	}
}
